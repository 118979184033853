<template>
  <mobile-screen
    :header="true"
    screen-class="resources-availability gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("notifications.notifications.send-at") }}
        </div>
        <template v-slot:right> </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      @submit="submitForm"
      ref="addResourceAvailabilityForm"
      v-slot="{ errors }"
      class="form change-password-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <button
                  class="clebex-item-range-cta"
                  @click.prevent="openDateTimePicker('date', 'from')"
                >
                  {{ displayDate(selectedDateFromParsed) }}
                </button>
              </li>
              <li class="clebex-item-range separator">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </li>
              <li class="clebex-item-range to">
                <button
                  @click.prevent="openDateTimePicker('date', 'to')"
                  class="clebex-item-range-cta"
                >
                  {{ displayDate(selectedDateToParsed) }}
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <ul
              class="clebex-item-content-range"
              style="justify-content: center;"
            >
              <li class="clebex-item-range from">
                <button
                  class="clebex-item-range-cta"
                  @click.prevent="openDateTimePicker('time-slot', 'from')"
                >
                  {{ displayTime(selectedHourFrom) }}
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li
          class="clebex-item-section-item overflow-hidden"
          v-if="showDateFrom || showDateTo"
        >
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
              :change-tab-fn="changeTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateFrom || showDateTo"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :cyclic-year="true"
                  :show-year="true"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom]"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
      </ul>
      <Field
        name="date_from"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedDateFrom"
        :disabled="submitting"
      />
      <Field
        name="date_to"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedDateTo"
        :disabled="submitting"
      />
      <Field
        name="time_from"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedHourFrom"
        :disabled="submitting"
      />
      <Field
        name="time_to"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedHourTo"
        :disabled="submitting"
      />

      <ul class="clebex-item-section">
        <li class="clebex-item-section-label-inner">
          <div class="clebex-label-content-wrapper">
            {{
              displayLabelName(
                "resources",
                "add-resource-availability",
                "on-the-days-of-the-week"
              )
            }}
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{
            'has-error': errors.one_checkbox_selected,
          }"
        >
          <span class="error-message" v-if="errors.one_checkbox_selected">
            {{
              displayLabelName(
                "resources",
                "add-resource-availability",
                "days-of-the-week-are-required"
              )
            }}
          </span>
          <ul class="checkbox-day-list">
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-mo"
                  name="mo"
                  v-model="days.mo"
                />
                <label for="day-mo">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "mo"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-tu"
                  name="tu"
                  v-model="days.tu"
                />
                <label for="day-tu">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "tu"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-we"
                  name="we"
                  v-model="days.we"
                />
                <label for="day-we">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "we"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-th"
                  name="th"
                  v-model="days.th"
                />
                <label for="day-th">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "th"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-fr"
                  name="fr"
                  v-model="days.fr"
                />
                <label for="day-fr">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "fr"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-sa"
                  name="sa"
                  v-model="days.sa"
                />
                <label for="day-sa">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "sa"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-su"
                  name="su"
                  v-model="days.su"
                />
                <label for="day-su">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "su"
                    )
                  }}.</label
                >
              </div>
            </li>
          </ul>
          <Field
            v-if="!isWeekDaySelected"
            name="one_checkbox_selected"
            as="input"
            type="hidden"
            rules="required"
            v-model="isWeekDaySelected"
            :disabled="submitting"
          />
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="clearValues()">
              {{ displayLabelName("search.filters.clear-all") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import { preferenceValueLabels } from "@/services/preference-options";

export default {
  name: "SendAt",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    ),
  },
  data() {
    return {
      fromTo: "from",
      formKey: 0,
      submitting: false,
      selectedHourFrom: false,
      selectedHourTo: false,
      timeFromVisible: false,
      timeToVisible: false,
      showDateFrom: false,
      showDateTo: false,
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedDateFromParsed: null,
      selectedDateToParsed: null,
      days: {
        mo: false,
        tu: false,
        we: false,
        th: false,
        fr: false,
        sa: false,
        su: false,
      },
      pillTabs: ["date", "time-slot"],
      preselectedTab: "date",
      helpSlug: "resources-availability-seasons",
      preferenceValueLabels: preferenceValueLabels,
    };
  },
  created() {
    if (this.datetimeFrom) {
      if (this.datetimeFrom.length == 5) {
        this.selectedDateFrom = null;
        this.selectedDateTo = null;
        this.selectedDateFromParsed = null;
        this.selectedDateToParsed = null;
      } else {
        this.selectedDateFrom = dayjs(this.datetimeFrom).format("YYYY-DD-MM");
        this.selectedDateTo = dayjs(this.datetimeTo).format("YYYY-DD-MM");
        this.selectedDateFromParsed = dayjs(this.datetimeFrom).format(
          "D MMMM YYYY"
        );
        this.selectedDateToParsed = dayjs(this.datetimeTo).format(
          "D MMMM YYYY"
        );
      }

      if (this.datetimeFrom.length == 5) {
        this.selectedHourFrom = this.datetimeFrom;
      } else if (this.datetimeFrom.includes("T")) {
        this.selectedHourFrom = this.datetimeFrom.split("T")[1].substring(0, 5);
      } else if (this.datetimeFrom.includes(" ")) {
        this.selectedHourFrom = this.datetimeFrom.split(" ")[1].substring(0, 5);
      }
    }

    if (this.day) {
      this.days.mo = this.day.includes("mo") ? true : false;
      this.days.tu = this.day.includes("tu") ? true : false;
      this.days.we = this.day.includes("we") ? true : false;
      this.days.th = this.day.includes("th") ? true : false;
      this.days.fr = this.day.includes("fr") ? true : false;
      this.days.sa = this.day.includes("sa") ? true : false;
      this.days.su = this.day.includes("su") ? true : false;
    }
  },
  watch: {
    activeDays() {
      this.setDay(this.activeDays);
      this.formSendAt();
    },
  },
  computed: {
    ...mapState("companyNotifications", [
      "datetimeFrom",
      "datetimeTo",
      "day",
      "sendAt",
    ]),
    ...mapGetters("settings", ["globalTimeFormat"]),
    ...mapGetters("settings", ["globalDateFormat"]),
    activeDays() {
      return Object.entries(this.days)
        .filter((entry) => entry[1]) // Filter out the entries where the value is false
        .map((entry) => entry[0]) // Map the filtered entries to their keys
        .join(", ");
    },
    isWeekDaySelected: {
      get() {
        const { mo, tu, we, th, fr, sa, su } = this.days;
        return mo || tu || we || th || fr || sa || su;
      },
      set() {
        this.days = {
          mo: false,
          tu: false,
          we: false,
          th: false,
          fr: false,
          sa: false,
          su: false,
        };
      },
    },
  },
  methods: {
    ...mapActions("companyNotifications", [
      "setDatetimeFrom",
      "setDatetimeTo",
      "setDay",
      "setSendAt",
    ]),
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = DateTime.fromISO(
          dates[0].eventDate
        ).toFormat("yyyy-dd-MM");

        if (!this.datetimeFrom) {
          this.setDatetimeFrom(dates[0].eventDate);
        } else {
          if (this.datetimeFrom.includes(" ")) {
            this.setDatetimeFrom(
              dates[0].eventDate + " " + this.datetimeFrom.split(" ")[1]
            );
          } else if (this.datetimeFrom.includes(":")) {
            this.setDatetimeFrom(dates[0].eventDate + " " + this.datetimeFrom);
          } else if (this.datetimeFrom.includes("-")) {
            this.setDatetimeFrom(dates[0].eventDate);
          }
        }
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].displayDate;
        if (!this.datetimeTo) {
          this.setDatetimeTo(dates[1].eventDate);
        } else {
          if (this.datetimeTo.includes(" ")) {
            this.setDatetimeTo(
              dates[1].eventDate + " " + this.datetimeTo.split(" ")[1]
            );
          } else if (this.datetimeTo.includes(":")) {
            this.setDatetimeTo(dates[1].eventDate + " " + this.datetimeTo);
          } else if (this.datetimeTo.includes("-")) {
            this.setDatetimeTo(dates[1].eventDate);
          }
        }
      } else {
        this.selectedDateTo = null;
        this.selectedDateToParsed = null;
      }

      this.formSendAt();
    },
    selectHour(hours) {
      if (hours.length == 1) {
        this.selectedHourFrom = hours[0];
      } else if (this.selectedHourFrom != hours[0]) {
        this.selectedHourFrom = hours[0];
      } else if (this.selectedHourFrom != hours[1] && hours[1] != undefined) {
        this.selectedHourFrom = hours[1];
      }

      if (!this.datetimeFrom) {
        this.setDatetimeFrom(this.selectedHourFrom);
      } else {
        if (this.datetimeFrom.includes(" ")) {
          this.setDatetimeFrom(
            this.datetimeFrom.split(" ")[0] + " " + this.selectedHourFrom
          );
        } else if (this.datetimeFrom.includes("-")) {
          this.setDatetimeFrom(this.datetimeFrom + " " + this.selectedHourFrom);
        } else if (this.datetimeFrom.includes(":")) {
          this.setDatetimeFrom(this.selectedHourFrom);
        }
      }

      if (!this.datetimeTo) {
        this.setDatetimeTo(this.selectedHourFrom);
      } else {
        if (this.datetimeTo.includes(" ")) {
          this.setDatetimeTo(
            this.datetimeTo.split(" ")[0] + " " + this.selectedHourFrom
          );
        } else if (this.datetimeTo.includes("-")) {
          this.setDatetimeTo(this.datetimeTo + " " + this.selectedHourFrom);
        } else if (this.datetimeTo.includes(":")) {
          this.setDatetimeTo(this.selectedHourFrom);
        }
      }

      this.formSendAt();
    },
    clearValues() {
      this.selectedDateFromParsed = null;
      this.selectedDateFrom = false;
      this.selectedDateToParsed = null;
      this.selectedDateTo = false;
      this.selectedHourFrom = null;
      this.selectedHourTo = null;
      this.days = {
        mo: false,
        tu: false,
        we: false,
        th: false,
        fr: false,
        sa: false,
        su: false,
      };
      this.setDatetimeFrom(null);
      this.setDatetimeTo(null);
      this.setDay(null);
      this.formSendAt();
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }

      return formatDate(date, "D MMMM", true);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "select-time");
      }
      const parsedTime = dayjs(
        time,
        this.globalTimeFormat.replace(" A", "")
      ).toDate();
      return formatDate(parsedTime.toString(), this.globalTimeFormat);
    },
    changeTab() {},
    dateDisplayError(errors) {
      if (errors.date_from) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "date-from-is-required"
        );
      }

      if (errors.date_to) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "date-to-is-required"
        );
      }
    },
    timeDisplayError(errors) {
      if (errors.time_from) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "time-from-is-required"
        );
      }

      if (errors.time_to) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "time-to-is-required"
        );
      }
    },
    openDateTimePicker(tab, fromTo) {
      if (this.preselectedTab !== tab) {
        this.preselectedTab = tab;
        if (fromTo === "from" && this.showDateTo) {
          this.showDateFrom = true;
          this.showDateTo = false;
        } else {
          this.showDateTo = true;
          this.showDateFrom = false;
        }
      } else {
        if (fromTo === "from") {
          this.showDateFrom = !this.showDateFrom;
        } else {
          this.showDateTo = !this.showDateTo;
        }
      }
    },
    reverseDateMonth(date) {
      const reversedDate = date.split("-");
      return `${reversedDate[1]}-${reversedDate[0]}`;
    },
    formSendAt() {
      this.setSendAt("");
      let datesLabel = this.displayLabelName(
        "notifications.notifications.dates"
      );
      let timeLabel = this.displayLabelName("notifications.notifications.time");
      let daysLabel = this.displayLabelName("notifications.notifications.days");

      if (this.selectedDateFrom && this.selectedDateTo) {
        this.setSendAt(
          datesLabel +
            ": " +
            dayjs(this.datetimeFrom).format(this.globalDateFormat) +
            " - " +
            dayjs(this.datetimeTo).format(this.globalDateFormat)
        );
        if (this.selectedHourFrom) {
          if (this.sendAt) {
            this.setSendAt(
              this.sendAt +
                "<br/>" +
                timeLabel +
                ": " +
                dayjs(this.datetimeFrom).format(this.globalTimeFormat)
            );
          } else {
            this.setSendAt(
              timeLabel +
                ": " +
                dayjs(this.datetimeFrom).format(this.globalTimeFormat)
            );
          }
        }
      }

      let daysOfWeek = "";
      if (this.day) {
        if (this.day.includes("mo"))
          daysOfWeek +=
            this.displayLabelName("notifications.notifications.mon") + ", ";
        if (this.day.includes("tu"))
          daysOfWeek +=
            this.displayLabelName("notifications.notifications.tue") + ", ";
        if (this.day.includes("we"))
          daysOfWeek +=
            this.displayLabelName("notifications.notifications.wed") + ", ";
        if (this.day.includes("th"))
          daysOfWeek +=
            this.displayLabelName("notifications.notifications.thu") + ", ";
        if (this.day.includes("fr"))
          daysOfWeek +=
            this.displayLabelName("notifications.notifications.fri") + ", ";
        if (this.day.includes("sa"))
          daysOfWeek +=
            this.displayLabelName("notifications.notifications.sat") + ", ";
        if (this.day.includes("su"))
          daysOfWeek +=
            this.displayLabelName("notifications.notifications.sun") + ", ";

        if (daysOfWeek != "") {
          if (this.sendAt != "") {
            this.setSendAt(
              this.sendAt + "<br/>" + daysLabel + ": " + daysOfWeek.slice(0, -2)
            );
          } else {
            this.setSendAt(daysLabel + ": " + daysOfWeek.slice(0, -2));
          }
        }
      }
    },
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
.select-time-picker .list-of-time-slots.list-of-minutes-wrapper.second {
  display: none;
}
</style>
